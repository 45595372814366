import React,{useEffect,useState} from 'react';
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import Create from './Create'
import Edit from './Edit'
import { fetchWrapper } from '../../../helpers';
import Pagination from "react-js-pagination";
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import { toast } from "react-toastify";

function List(){
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)
	const [loading,setLoading] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [listCount, setListCount] = useState(0); 
	const [activePage, setActivePage] = useState(1);

  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
    }, [activePage]);

  const loadList = async () => {
  	  setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/api/weapon-brand?page=${activePage}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows);
      setListCount(data.count);
      setLoading(false)
  }

  const meClose = () => {
  		
		   onClose();
		   
	}
	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}

	const meDelete = async (rs) => {
			const aa = window.confirm("Are you sure delete this recored");
			if(aa){
				 setLoading(true)
				 const url = `${process.env.REACT_APP_API_URL}/api/weapon-brand/${rs.id}`;
	  		try {
		      await fetchWrapper.delete(url);
		      loadList();
		      setLoading(false);
		      toast(`Record has been deleted`, {
		        type: "info"
		      });
		    } catch (error) {
		      
		      setLoading(false);
		      toast(`This Record is being used`, {
		        type: "info"
		      });
		    }
			}
	}

	const onChangePaginate = (pageNumber) => {
    setActivePage(pageNumber);
    // to set offset
    console.log(pageNumber);
  };

  return(
    <>
    
    <CustomModalLink
          showModalButtonText="Edit"
          modalHeader="Edit"
       		isOpenEdit={isOpenEdit}
       		onCloseEdit={onCloseEdit}
       		onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
      <div className="setting_page_section">
      {loading &&
      	<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
		    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
				<HStack justifyContent="space-between" mb="25px">
					<Box>
						<Text fontSize="20px" fontWeight="500" color="#000" my="0px">Weapon Brands</Text>
					</Box>

					<Box>
						<CustomModal
              showModalButtonText="Add New Weapon Brand"
              modalHeader="Create Weapon Brand"
              isOpen={isOpen}
           		onClose={onClose}
           		onOpen={onOpen}
              modalBody=<Create meClose={meClose} loadList={loadList} />
            />
					</Box>		
				</HStack>

				<div className="table_section">
					<TableContainer width="100%">
					  <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
					    <Thead>
					      <Tr>
					        <Th>Weapon Brand</Th>
					        <Th>Country</Th>
					        <Th></Th>
					      </Tr>
					    </Thead>
					    <Tbody>
					    {list?.map((rs,index) => {

					    		return(



					    			<Tr key={index}>
							        <Td>{rs.name}</Td>
							        <Td>{rs.Country?.name}</Td>
							        <Td>

							        	<HStack mb="5px">


	 <a  
	 
	 onClick={() => meEdit(rs)}>
        <BsPencil />
      </a>  
      <a  
	 onClick={() => meDelete(rs)}>
        
        <BsFillTrashFill />
      </a>
      </HStack>
							        </Td>
							      </Tr>

					    		)


					    })}
					      
					      
					    </Tbody>
					    
					  </Table>

					  <Pagination
							 activePage={activePage}
							 itemsCountPerPage={50}
							 totalItemsCount={listCount}
							 pageRangeDisplayed={5}
							 onChange={onChangePaginate}
							 innerClass="pagination"
							 itemClass="page-item"
							 activeLinkClass="page-link active"
							 linkClass="page-link"
							 prevPageText="Previous"
							 nextPageText="Next"
							/>
					</TableContainer>
				</div>
			</div>

      
		</>
    )
}

export default List;