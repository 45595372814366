import React from 'react';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import { Link } from "react-router-dom";

function LeftNav({loadPage}){
	return(
		<>
			<Box borderRightWidth="1px" borderStyle="solid" borderColor="#ccc" py="20px">
				<UnorderedList m="0px" p="0px" listStyleType="none">
				  <ListItem pb="15px" borderBottomWidth="1px" borderColor="#ccc" borderStyle="solid">
				  	<Link className="setting_nav_link dropdown_link">Weapon</Link>

				  	<UnorderedList p="0px" pl="50px" m="0px" listStyleType="none">
				  		<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('ammunitionbrand')} >Ammunition Brands</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('caliber')} >Caliber</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('weapontype')} >Weapon Type</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('weaponbrand')} >Weapon Brands</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('weaponmodel')} >Weapon Models</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('weaponparts')} >Weapon Parts</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('purchasecondition')} >Purchase Condition</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('servicetype')} >Service Type</Link>
					  	</ListItem>
				  	</UnorderedList>
				  </ListItem>

				  <ListItem pb="15px" borderBottomWidth="1px" borderColor="#ccc" borderStyle="solid">
				  	<Link className="setting_nav_link dropdown_link">License</Link>
				  	<UnorderedList m="0px" p="0px" pl="50px" listStyleType="none">
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('issuancequota')} >Issuance Quota</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('issuanceauthority')} >Issuing Authority</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('jurisdication')} >Jurisdiction </Link>
					  	</ListItem>
				  	</UnorderedList>
				  </ListItem>

				  <ListItem pb="15px" borderBottomWidth="1px" borderColor="#ccc" borderStyle="solid">
				  	<Link className="setting_nav_link dropdown_link">Shooters Log Book</Link>

				  	<UnorderedList p="0px" pl="50px" m="0px" listStyleType="none">
				  		<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('shootingrange')} >Shooting Ranges</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('bullettype')} >Bullet Type</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('targettype')} >Target Type</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('shootingposition')} >Shooting Position</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('weathercondition')} >Weather Conditions</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('winddirection')} >Wind Direction</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('terrain')} >Terrain</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('brightness')} >Brightness</Link>
					  	</ListItem>
				  	</UnorderedList>
				  </ListItem>

				  <ListItem pb="15px" borderBottomWidth="1px" borderColor="#ccc" borderStyle="solid">
				  	<Link className="setting_nav_link dropdown_link">Localization</Link>

				  	<UnorderedList m="0px" p="0px" pl="50px" listStyleType="none">
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('country')} >Country</Link>
					  	</ListItem>
					  	<ListItem mt="10px">
					  		<Link className="setting_nav_link" onClick={() => loadPage('city')} >City</Link>
					  	</ListItem>
				  	</UnorderedList>
				  </ListItem>
				  <ListItem borderBottomWidth="1px" borderColor="#ccc" borderStyle="solid" pl="50px" mt="10px" pb="10px">
				  	<Link className="setting_nav_link" onClick={() => loadPage('user')}>Users</Link>
				  </ListItem>
				</UnorderedList>
			</Box>
		</>
	);
}

export default LeftNav;