import React,{useEffect,useState} from 'react';
import { HStack, Box, Image, Text, Button, Divider } from '@chakra-ui/react';
import { fetchWrapper } from '../../../helpers';
import axios from 'axios';



const VeiwLicence =({rs,meCloseEdit})=>{
	


	return(
		<>
		<h1>License</h1>
		<Box>
		<Image src={`http://localhost:3031/uploads/customer/${rs.id}/license/${rs.license_image}`} style={{width: "468px",
    margin: "20px auto"}} />
		</Box>

		

	<Divider borderStyle="solid" borderColor="#ccc"	 />
		<Button py="10px" px="25px" color="#001F3F"  fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            colorScheme='whiteAlpha'
		            onClick={meCloseEdit}
		            type='button'
          		>
           		 Close
        </Button>
          	</>
		)
}

export default VeiwLicence;