import React,{useEffect,useState} from 'react';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../../components/form'
import { HStack, Box, Text } from '@chakra-ui/react';
import { toast } from "react-toastify";
import { fetchWrapper } from '../../../helpers';




function AmmunitionCreate({loadList,meClose,customer_id,customerName}){

	const [formData, setFormData] = useState({
    licence_id: '',
    purchase_from: '',
    brand_id: '',
    caliber_id:'',
    purchase_qty:'',
    purchase_date: '',
    customer_id:customer_id,
   
    
  });

  const FormSchema = Yup.object().shape({
	   licence_id: Yup.string().required('Required'),
	   purchase_from: Yup.string().required('Required'),
	   brand_id: Yup.string().required('Required'),
	   caliber_id: Yup.string().required('Required'),
	   purchase_qty: Yup.string().required('Required'),
	   purchase_date: Yup.string().required('Required'),
	

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/ammunition-record`;
  		 await fetchWrapper.post(url,data)

  		 meClose();
  		 loadList()
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
  		 
	}

	
	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >

			 	

			 	 

			 	<HStack justifyContent="space-between">
			 	<Box w="48%">
			 			<InputText name="licence_id" title="License"  />
			 		</Box>
			 		<Box w="48%">
			 			<InputText name="purchase_from" title="Purchased From"  />
			 		</Box>

			 		
			 	</HStack>
			 	
			 	<HStack justifyContent="space-between">
			 	<Box w="48%">
			 			<InputText name="brand_id" title="Brand"  />
			 		</Box>
			 		<Box w="48%">
			 			<InputText name="caliber_id" title="Caliber"  />
			 		</Box>


			 		
			 	</HStack>

			 	<HStack justifyContent="space-between">
			 	<Box w="48%">
			 			<InputText name="purchase_qty" title="Quantity Purchased"  />
			 		</Box>
			 		<Box w="48%">
			 			<InputDateField inputProps={{type:'date'}}  name="purchase_date" title="Purchase Date"  />
			 		</Box>

			 		
			 	</HStack>

			 	
			 	


         <Button py="10px" px="25px" color="#fff" bg="#001F3F" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            
		            type='submit'
          		>
           		 Save
          		</Button>

			</Form>

			</>

	)
}

export default AmmunitionCreate;