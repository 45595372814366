import React,{useEffect,useState} from 'react';
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import CustomerModalLink from '../../../components/CustomerModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { BsPlusCircleFill, BsFillCameraFill } from "react-icons/bs";
import {Link} from 'react-router-dom';

import LicenseCreate from './Create'
import LicenseEdit from './Edit'
import { fetchWrapper } from '../../../helpers';
import moment from 'moment';
import VeiwLicence from './Veiw.js'

function LicenseList({customer_id,customerName}){
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)

	const [show,setShow] = useState(false);
  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/license?customer_id=${customer_id}`;
      const data  = await fetchWrapper.get(url)

      setList(data.rows)
      setShow(show => !show);
  }



  const meClose = () => {
  		
		   onClose();
		   
	}
	
	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);

		setModalEditView(<LicenseEdit rs={rs} customerName={customerName} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}
	const meVeiw = (rs) => {
		setIsOpenEdit(true);

		setModalEditView(<VeiwLicence rs={rs} customerName={customerName} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}

  return(
    <>
    	<CustomModalLink
        showModalButtonText="Edit"
        modalHeader="Edit"
     		isOpenEdit={isOpenEdit}
     		onCloseEdit={onCloseEdit}
     		onOpenEdit={onOpenEdit}
        
        modalBody={modalEditView}
    	/>

    	<HStack py="10px" px="20px" justifyContent="space-between" bg="#001F3F" borderBottomWidth="1px" borderColor="#ccc">
								
								<Box>
									<Text fontSize="14px" fontWeight="500" color="#fff" margin="0px">License's</Text>
								</Box>

								<Box>
									<CustomerModalLink
										className="add_license_link"
										icon={<BsPlusCircleFill />}
			              showModalButtonText="Add License"
			              modalHeader="Create License"
			              isOpen={isOpen}
			           		onClose={onClose}
			           		onOpen={onOpen}
			              modalBody=<LicenseCreate customerName={customerName}  meClose={onClose} customer_id={customer_id} meClose={meClose} loadList={loadList} />
			            />
								</Box>
							</HStack>


      <div className="inner_table_section">
				<TableContainer width="100%">
				  <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
				      <Tr>
				        <Th>Issuing Authority</Th>
				        <Th>License No</Th>
				        <Th>Tracking No</Th>
				        <Th>Date of Issuance</Th>
				        <Th>Valid Till</Th>
				        <Th>Issuance Quota</Th>
				        <Th>Jurestriction </Th>
				        <Th>Ammunition Limit</Th>
				        <Th>Caliber</Th>
				        <Th>Weapon No</Th>
				        <Th></Th>
				        <Th></Th>
				      </Tr>
				    </Thead>

				    <Tbody>
					    {list?.map((rs,index) => {

						    		return(



						    			<Tr key={index}>
								        <Td>
								     
								        
								        <Image src= {`http://localhost:3031/uploads/customer/${customer_id}/license/${rs.license_image}`} h="50px" />
								        </Td>
								        <Td>{rs.license_number}</Td>
								        <Td>{rs.tracking_number}</Td>
								        <Td>{moment(rs.date_issue).format('DD MMMM, YYYY')}</Td>
								        <Td>{moment(rs.valid_till).format('DD MMMM, YYYY')}</Td>
								        <Td>{rs.IssueQuotum?.name}</Td>
								        <Td>{rs.Jurisdiction?.name}</Td>
								        <Td>{rs.ammunition_limit}</Td>
								        <Td>{rs.WeaponType?.Caliber?.name}</Td>
								        <Td>{rs.WeaponType?.name}</Td>
								        <Td>

							        	<a bg="#363B78" padding="20px" fontSize="14px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#fff" 
							        		 onClick={() => meEdit(rs)}>
										        Edit
										      </a>
							        </Td>
							         <Td>

							        	<a bg="#363B78" padding="20px" fontSize="14px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#fff" 
							        		 onClick={() => meVeiw(rs)}>
										        Veiw
										      </a>
							        </Td>
								      </Tr>

						    		)


						    })}
				    </Tbody>
				  </Table>
				</TableContainer>
			</div>

      
		</>
    )
}

export default LicenseList;