import React from 'react';
import { HStack, Box, Image } from '@chakra-ui/react';
import Headerlogo from '../images/header_logo.png';
import { Link,useNavigate } from "react-router-dom";
import "../index.css";

function Header(){
	const navigate = useNavigate();
 const logout = () => {
      
        localStorage.removeItem('user');
        navigate('/login');
  }

	return(
		<HStack h="60px" bg="#001F3F" py="10px" px="50px" justifyContent="space-between">

			<Box>
				<Link to="/dashboard">
					<Image src={Headerlogo} alt='Gun Guru' />
				</Link>
				
			</Box>

			<Box ml="20px">
				<HStack>
					<Box me="30px">
						<Link className="header_link" to="/dashboard">Dashboard</Link>
					</Box>

					<Box me="30px">
						<Link className="header_link" to="/customer">Customer</Link>
					</Box>

					<Box me="30px">
						<Link className="header_link">License Renewal</Link>
					</Box>

					<Box>
						<Link className="header_link" to="/settings">Settings</Link>
					</Box>

					
				</HStack>
			</Box>

			<Box>
				<Box>
					<a className="header_link" onClick={() => logout()}>Logout</a>
				</Box>
			</Box>
		</HStack>
	);
}

export default Header;