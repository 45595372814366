import React,{useEffect,useState} from 'react';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import LeftNav from './settings/LeftNav';
import Caliber from './settings/Caliber';
import WeaponType from './settings/WeaponType';
import WeaponBrand from './settings/WeaponBrand';
import WeaponModel from './settings/WeaponModel';
import WeaponPart from './settings/WeaponPart';
import PurchaseCondition from './settings/PurchaseCondition';
import ServiceType from './settings/ServiceType';
import IssuanceQuota from './settings/IssuanceQuota';
import IssuanceAuthority from './settings/IssuanceAuthority';
import Jurisdication from './settings/Jurisdication';
import AmmunitionBrand from './settings/AmmunitionBrand';
import ShootingRange from './settings/ShootingRange';
import BulletType from './settings/BulletType';
import TargetType from './settings/TargetType';
import ShootingPosition from './settings/ShootingPosition';
import WeatherCondition from './settings/WeatherCondition';
import WindDirection from './settings/WindDirection';
import Terrain from './settings/Terrain';
import Brightness from './settings/Brightness';
import Country from './settings/Country';
import City from './settings/City';
import User from './settings/User';
import Header from '../components/Header';

function Settings(){
	 const [ getPage, setGetPage ] = useState(false);


	
	 useEffect(() => {
        loadPage('caliber')
       
       
        
    }, []);

	const loadPage = (page_id) => {

		if(page_id == 'caliber'){
			setGetPage(<Caliber />)
		}

		if(page_id == 'weapontype'){
			setGetPage(<WeaponType />)
		}
		if(page_id == 'weaponbrand'){
			setGetPage(<WeaponBrand />)
		}
		if(page_id == 'weaponmodel'){
			setGetPage(<WeaponModel />)
		}
		if(page_id == 'weaponparts'){
			setGetPage(<WeaponPart />)
		}
		if(page_id == 'purchasecondition'){
			setGetPage(<PurchaseCondition />)
		}
		if(page_id == 'servicetype'){
			setGetPage(<ServiceType />)
		}
		if(page_id == 'issuancequota'){
			setGetPage(<IssuanceQuota />)
		}
		if(page_id == 'issuanceauthority'){
			setGetPage(<IssuanceAuthority />)
		}
		if(page_id == 'jurisdication'){
			setGetPage(<Jurisdication />)
		}
		if(page_id == 'ammunitionbrand'){
			setGetPage(<AmmunitionBrand />)
		}
		if(page_id == 'shootingrange'){
			setGetPage(<ShootingRange />)
		}
		if(page_id == 'bullettype'){
			setGetPage(<BulletType />)
		}
		if(page_id == 'targettype'){
			setGetPage(<TargetType />)
		}
		if(page_id == 'shootingposition'){
			setGetPage(<ShootingPosition />)
		}
		if(page_id == 'weathercondition'){
			setGetPage(<WeatherCondition />)
		}
		if(page_id == 'winddirection'){
			setGetPage(<WindDirection />)
		}
		if(page_id == 'terrain'){
			setGetPage(<Terrain />)
		}
		if(page_id == 'brightness'){
			setGetPage(<Brightness />)
		}
		if(page_id == 'country'){
			setGetPage(<Country />)
		}
		if(page_id == 'city'){
			setGetPage(<City />)
		}
		if(page_id == 'user'){
			setGetPage(<User />)
		}
	} 

	return(
		<>
		<Header />

		<div>
			<Box>
				<Text ml="50px" mt="20px" mb="10px" fontSize="20px" fontWeight="500" color="#000" fontFamily="Lato, sans-serif">Settings</Text>
				<HStack alignItems="top">
					<Box w="20%">
						<LeftNav loadPage={loadPage} />
					</Box>

					<Box w="80%" pe="50px">
						{getPage}
					</Box>
				</HStack>
			</Box>
		</div>				
		</>
	);
}

export default Settings;