import React,{useEffect,useState} from 'react';
import Header from '../components/Header';
import { HStack, Box, Image, Text, Button, Divider } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useDisclosure
} from '@chakra-ui/react'
import "../index.css";
import { Link,useLocation,useNavigation } from "react-router-dom";
import Authorityimg from '../images/authority_img.png';
import Profileimg from '../images/profile_img.png';
import Caliber9mmImage from '../images/9mm.png';
import Caliber556Image from '../images/556.png';
import { BsPlusCircleFill, BsFillCameraFill } from "react-icons/bs";
import CustomerModalLink from '../components/CustomerModalLink'
import LicenseCreate from './customer/license/Create'
import LicenseList from './customer/license/List'
import WeaponCreate from './customer/weapon/Create'
import WeaponList from './customer/weapon/List'
import Edit from './customer/license/Edit'
import { fetchWrapper } from '../helpers';
import AmmuntionList from './customer/ammunation/List';
import moment from 'moment';
import PictureCreate from './Picture_upload.js';
import Viewshow from './Picture_veiw.js'
import CustomModalLink from '../components/CustomModalLink'

function CustomerDetail(){
	const {state} = useLocation();
	const id = state.id
	const [list,setList] = useState([])
	const [customerDetail,setCustomerDetail] = useState({})
	const { isOpen, onOpen, onClose} = useDisclosure();
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
  useEffect(() => {
  			loadCustomer();
        loadList()
       
       
        
    }, [id]);

  const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}


  const loadCustomer = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/customer/${id}`;
      const data  = await fetchWrapper.get(url)
      setCustomerDetail(data)
      // console.log(data)
  }

  const loadList = async () => {
      // const url = `${process.env.REACT_APP_API_URL}/api/license?customer${id}`;
      // const data  = await fetchWrapper.get(url)
      // setList(data)
  }
 
 const meClose = () => {
    onClose();
  };

 const meEdit = (view) => {
		setIsOpenEdit(true);
		if(view == 'upload'){
				setModalEditView(<PictureCreate rs={customerDetail}  meCloseEdit={meCloseEdit} loadList={loadList} />)		
		}

		if(view == 'view'){
				setModalEditView(<Viewshow rs={customerDetail}  meCloseEdit={meCloseEdit} loadList={loadList} />)		
		}
		
	}

	return(
		<>

		<CustomModalLink
        showModalButtonText="Edit"
        modalHeader="Edit"
     		isOpenEdit={isOpenEdit}
     		onCloseEdit={onCloseEdit}
     		onOpenEdit={onOpenEdit}
        
        modalBody={modalEditView}
    	/>
			<Header />

			<div className="body_section">
				<HStack mb="15px">
					<Box>
						<Link to="/Customer">Back to Customers</Link>
					</Box>	
				</HStack>
				<HStack mb="20px">
					<Box>
						<Text fontSize="20px" fontWeight="600" color="#000" textAlign="center" >{customerDetail.first_name} {customerDetail.last_name}</Text>
					</Box>	
				</HStack>

				<HStack alignItems="top">
					<Box className="left_section" me="20px">
						<Box p="15px" borderWidth="1px" borderStyle="solid" borderColor="#ccc" borderRadius="5px">
							<Box position="relative" w="200px" h="200px" margin="auto" display="none">
								<Image />
								<Box py="3px" pl="10px" width="100%" bg="rgba(0, 0, 0, 0.75)" position="absolute" bottom="0px">
									<BsFillCameraFill w="20px" h="20px" color="#fff" />
								</Box>
							</Box>

							

							<Divider borderStyle="solid" borderColor="#ccc"	display="none" />

							<Box my="15px">
								<Text fontSize="14px" fontWeight="700" color="#000" m="0px" mb="15px">Basic Information</Text>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">Contact No:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{customerDetail.phone}</Text>
									</Box>
								</HStack>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">CNIC:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{customerDetail.cnic}</Text>
									</Box>
								</HStack>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">Date of Birth:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{moment(customerDetail.dob).format('DD MMMM, YYYY')}</Text>
									</Box>
								</HStack>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">Address:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{customerDetail.address}</Text>
									</Box>
								</HStack>

								<HStack justifyContent="flex-start" mb="10px">
									<Box w="40%">
										<Text fontSize="14px" fontWeight="700" color="#000">City:</Text>
									</Box>

									<Box w="60%">
										<Text fontSize="14px" fontWeight="500" color="#000">{customerDetail.City?.name}</Text>
									</Box>
								</HStack>
							</Box>

							<Divider borderStyle="solid" borderColor="#ccc" />

							<Box my="15px">
								<Text fontSize="14px" fontWeight="700" color="#000" m="0px" mb="15px">Files</Text>

								<HStack justifyContent="space-between" alignItems="center" mb="10px">
									<Box>
										<Text fontSize="14px" fontWeight="700" color="#000">CNIC Front:</Text>
									</Box>

										<Box   px="30px" py="6px" bg="#20E100" borderRadius="25px"	>
											<Box  color="#fff" fontSize="14px" fontWeight="700">
												<div onClick={() => meEdit('upload')} id="uploaded">Uploaded</div>
											</Box>
										</Box>
										<Box>
											<Box  color="#1400FF" fontSize="14px" fontWeight="700">
												<div onClick={() => meEdit('view')} id="uploaded">View</div>
											</Box>
										</Box>

								</HStack>


								<HStack justifyContent="space-between" alignItems="center" display="none">
									<Box>
										<Text fontSize="14px" fontWeight="700" color="#000">CNIC Back:</Text>
									</Box>

									<Box>
										<Box w="110px" bg="#EC0000" py="3px" borderRadius="25px">
											<Text textAlign="center" color="#fff" fontSize="14px" fontWeight="500">Not Uploaded</Text>
										</Box>
									</Box>

									<Box>
										<Link>View File</Link>
									</Box>
								</HStack>
							</Box>
						</Box>
					</Box>

					<Box className="right_section">
						<Box mb="20px" borderWidth={1} borderStyle="solid" borderColor="#ccc" borderRadius="5px">


							<LicenseList customerName={`${customerDetail.first_name} ${customerDetail.last_name}`} customer_id={id} />
						</Box>

						<Box mb="20px" borderWidth={1} borderStyle="solid" borderColor="#ccc" borderRadius="5px">
							

							<WeaponList customerName={`${customerDetail.first_name} ${customerDetail.last_name}`} customer_id={id} />
						</Box>

						<Box mb="20px" borderWidth={1} borderStyle="solid" borderColor="#ccc" borderRadius="5px">
							

							<AmmuntionList customerName={`${customerDetail.first_name} ${customerDetail.last_name}`} customer_id={id} />
						</Box>
						
					</Box>
				</HStack>
			</div>



			
		</>
	)
}

export default CustomerDetail;