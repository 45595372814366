import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController
);

export default function BarChart() {
  const data = {
    labels: ["Pistol", "Rifle", "", "Shotgun", "566", "9mm"],
    datasets: [
      {
        label: "Weapons",
        backgroundColor: "#001F3F",
        borderColor: "#001F3F",
        borderWidth: 1,

        data: [12, 19, 30, 15, 12, 18]
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: true
      }
    },
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 10,
        bottom: 5
      },
      margin: {
        left: 5,
        right: 5,
        top: 5,
        bottom: 5
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: { dash: [6, 6], display: true },
        grid: {
          display: true // Display grid lines for the y-axis
        },
        ticks: {
          padding: 15
        }
      },
      x: {
        beginAtZero: true,
        border: { display: true },
        grid: {
          display: false // Display grid lines for the y-axis
        },
        ticks: {
          padding: 7
        }
      }
    },
    elements: {
      bar: {
        borderWidth: 0.7,
        width: 600,
      }
    }
  };

  return (
    <div id="chart">
      <Bar  data={data} options={options}  />
    </div>
  );
}
