import React,{useEffect,useState} from 'react';
import { HStack, Box, Image, Text, Button, Divider } from '@chakra-ui/react';
import { fetchWrapper } from '../helpers';
import axios from 'axios';



function Viewshow({rs,meCloseEdit}) {

console.log(rs.cnic_front)



	return(
		<>
		<h1>CNIC</h1>
		<Box>
		<Image src={`http://localhost:3031/uploads/customer/${rs.id}/cnic/${rs.cnic_front}`} style={{width: "468px",
    margin: "20px auto"}} />
		</Box>

		

	<Divider borderStyle="solid" borderColor="#ccc"	 />
		<Button py="10px" px="25px" color="#001F3F"  fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            colorScheme='whiteAlpha'
		            onClick={meCloseEdit}
		            type='button'
          		>
           		 Close
        </Button>
          	</>
		)
}

export default Viewshow;