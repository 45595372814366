import React,{useEffect,useState} from 'react';
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom';

import WeaponCreate from './Create'
import WeaponEdit from './Edit'
import { fetchWrapper } from '../../../helpers';
import CustomerModalLink from '../../../components/CustomerModalLink'
import { BsPlusCircleFill, BsFillCameraFill } from "react-icons/bs";
import moment from 'moment'
function WeaponList({customer_id,customerName}){
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)

  const [show,setShow] = useState(false);
  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/weapon-record-customer?customer_id=${customer_id}`;
      const data  = await fetchWrapper.get(url)

      setList(data.data)
      setShow(show => !show);
			// console.log(data)
  }


  const meClose = () => {
  		
		   onClose();
		   
	}
	
	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<WeaponEdit customerName={customerName} rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}

  return(
    <>
    	<CustomModalLink
        showModalButtonText="Edit"
        modalHeader="Edit"
     		isOpenEdit={isOpenEdit}
     		onCloseEdit={onCloseEdit}
     		onOpenEdit={onOpenEdit}
        
        modalBody={modalEditView}
    	/>


    	<HStack py="10px" px="20px" justifyContent="space-between" bg="#001F3F" borderBottomWidth="1px" borderColor="#ccc">
								<Box>
									<Text fontSize="14px" fontWeight="500" color="#fff" margin="0px">Weapon’s</Text>
								</Box>

								<Box>
									<CustomerModalLink
										className="add_license_link"
										icon={<BsPlusCircleFill />}
			              showModalButtonText="Add Weapon"
			              modalHeader="Create Weapon"
			              isOpen={isOpen}
			           		onClose={onClose}
			           		onOpen={onOpen}
			              modalBody=<WeaponCreate customerName={customerName} customer_id={customer_id} meClose={meClose} loadList={loadList} />
			            />
								</Box>
							</HStack>

      <div className="inner_table_section">
				<TableContainer width="100%">
				  <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
				      <Tr>
				        <Th>Caliber</Th>
				        <Th>Make</Th>
				        <Th>Model</Th>
				        <Th>Weapon No</Th>
				        <Th>License No</Th>
				        <Th>Purchase Date</Th>
				        <Th>Shots Fired</Th>
				        <Th></Th>
				      </Tr>
				    </Thead>

				    <Tbody>
					    {list?.map((rs,index) => {

						    		return(



						    			<Tr key={index}>
								        <Td>
								        	{rs?.Caliber?.name}
								        </Td>
								        <Td>{rs.WeaponBrand?.name}</Td>
								        <Td>{rs.WeaponModel?.name}</Td>
								        <Td>{rs.weapon_no}</Td>
								        <Td>{rs.License?.name}</Td>
								        <Td>
								        
								        {moment(rs.purchase_date).format('DD MMMM, YYYY')}
								        </Td>
								        <Td>{rs.jurisdiction_id}</Td>
								        <Td>
								        	<HStack justifyContent="end">
								        		<a>View Service Record</a>
								        		<a>View Firing Record</a>
								        	</HStack>
								        </Td>
								        <Td>

								        	<a bg="#363B78" padding="20px" fontSize="14px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#fff" 
								        		 onClick={() => meEdit(rs)}>
											        Edit
											      </a>
								        </Td>
								      </Tr>

						    		)
						    })}
				    </Tbody>
				  </Table>
				</TableContainer>
			</div>

      
		</>
    )
}

export default WeaponList;